/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable prettier/prettier */

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Switch, Text, Flex } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { ComediansGrid, NumberContentFoundStyles } from '../components/ComediansGrid';
import { getTypeFormatted } from '../utils/getComedianTypeFormatted';
import { PageStyles } from '../pages';
import { ComediansCategoryHeaderStyles } from '../pages/comedians';
import { AbsoluteHeaderStyles } from '../components/AdviceGrid';
import { ComediansSortByHeader } from '../components/ComediansSortByHeader';
import { GenericHeaderStyles } from '../components/GenericHeader';

export default function SingleComedianCategoryPage({ data }) {
    const [sortByPopular, setSortByPopular] = useState(false);
    const category = data ? data.category : null;
    if(!category) return <p>Advice didn't make it 😞</p>
    let { comedians } = category;
    const type = getTypeFormatted(category.category);
    const typeFormatted = type || 'comedian';
    // if (comedians.length !== 1) {
    //   typeFormatted = `${typeFormatted}s`;
    // }
    if (sortByPopular) {
      comedians = comedians.sort((a, b) =>
        a.viewsAllTime > b.viewsAllTime
          ? -1
          : a.viewsAllTime < b.viewsAllTime
          ? 1
          : 0
      );
    } else {
      comedians = comedians.sort((a, b) =>
        a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0
      );
    }
  return (
    <>
      <SEO title={`${category.category} | Yuks`} />
      <PageStyles>
        <ComediansCategoryHeaderStyles>
          <AbsoluteHeaderStyles>
            <ComediansSortByHeader noUnderline />
          </AbsoluteHeaderStyles>
        </ComediansCategoryHeaderStyles>
        <GenericHeaderStyles>
          <Text className="header">Comedians</Text>
          <Flex paddingRight="3px" justifySelf="end" alignItems="center">
            <Text
              mr="5px"
              className="switch-text"
              id={sortByPopular ? 'selected' : ''}
              onClick={() => setSortByPopular(true)}
            >
              Popular
            </Text>
            <Switch
              colorScheme="blackAlpha"
              transform="rotate(180deg)"
              isChecked={sortByPopular}
              size="sm"
              mb="-4.5px"
              mr="5px"
              onChange={() => setSortByPopular(!sortByPopular)}
            />
            <Text
              className="switch-text"
              id={!sortByPopular ? 'selected' : ''}
              onClick={() => setSortByPopular(false)}
            >
              A-Z
            </Text>
          </Flex>
        </GenericHeaderStyles>
        <NumberContentFoundStyles>There are {comedians.length} {typeFormatted}.</NumberContentFoundStyles>
        <ComediansGrid comedians={comedians} standalonePage category={type} />
      </PageStyles>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    category: sanityComedianCategory(slug: { current: { eq: $slug }}) {
        id
        category
        slug {
            current
        }
        comedians {
            id
            firstName
            lastName
            slug {
                current
            }
            avatar {
                asset {
                    fluid {
                    ...GatsbySanityImageFluid
                    }
                }
            }
            categories {
                id
                category
                slug {
                    current
                }
            }
            viewsAllTime
        }
    }
  }
`;