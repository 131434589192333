export const getTypeFormatted = (type) => {
  let typeFormatted = type ? `${type.toLowerCase()}s` : 'comedians';
  if (typeFormatted.includes('born')) {
    typeFormatted = `comedians ${typeFormatted.slice(
      0,
      typeFormatted.length - 1
    )}`;
  }
  if (typeFormatted === 'deads') {
    typeFormatted = 'comedians who are dead';
  }
  if (typeFormatted === 'comedians in cars alumnis') {
    typeFormatted = 'Comedians in Cars Getting Coffee alums';
  }
  if (typeFormatted === 'emerson college graduates') {
    typeFormatted = 'Emerson College grads';
  }
  if (typeFormatted === 'in an apatow productions') {
    typeFormatted = 'comedians who were in a Judd Apatow TV show or movie';
  }
  if (typeFormatted === 'joe rogan experience alumnis') {
    typeFormatted = 'Joe Rogan Experience alums';
  }
  if (typeFormatted === 'snl alumnis') {
    typeFormatted = 'SNL alums';
  }
  if (typeFormatted === 'snl hosts') {
    typeFormatted = 'previous SNL hosts';
  }
  if (typeFormatted === 'wtf podcast alumnis') {
    typeFormatted = 'WTF podcast alums';
  }
  if (typeFormatted === 'you made it weird alumnis') {
    typeFormatted = 'You Made It Weird alums';
  }
  return typeFormatted;
};
